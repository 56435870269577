import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { OAuthAuthenticationService } from 'src/app/OAuth/oAuthAuthentication.service';
import { UserDetailsService } from 'src/app/user-details.service';
import { WorkspaceService } from 'src/app/workspace.service';

@Component({
  selector: 'app-no-workspace',
  templateUrl: './no-workspace.component.html',
  styleUrls: ['./no-workspace.component.scss'],
})
export class NoWorkspaceComponent implements OnInit {
  userComparisonDate = new Date(2024, 0, 19);
  globalComparisonDate = new Date(2024, 3, 1);
  showFreeWorkspaceMessage = false;

  constructor(
    public workspaceService: WorkspaceService,
    private router: Router,
    public authService: OAuthAuthenticationService,
    private userDetailsService: UserDetailsService
  ) {}

  ngOnInit(): void {
    if (new Date().valueOf() > this.globalComparisonDate.valueOf()) {
      this.showFreeWorkspaceMessage = false;
    } else {
      if (!this.authService.email) {
        return;
      }
      this.userDetailsService
        .getUser(this.authService.email)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.showFreeWorkspaceMessage = result?.created
              ? result?.created?.valueOf() < this.userComparisonDate.valueOf()
              : true;
          },
        });
    }
  }

  get email(): string {
    return this.authService.email?.toLocaleLowerCase() ?? '';
  }

  public logout() {
    this.router.navigate(['/logout']);
  }
}
