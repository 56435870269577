import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any, maxSeconds?: number, format?: string): any {
    if (!value) {
      return 'Nie';
    }

    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    if (seconds < 29) {
      // less than 30 seconds ago will show as 'Just now'
      return 'gerade eben';
    }
    if (maxSeconds && seconds >= maxSeconds) {
      if (format) {
        return 'am ' + formatDate(value, format, 'de_DE', 'UTC');
      }
      return 'am ' + formatDate(value, 'MMM. dd, yyyy HH:mm', 'de_DE', 'UTC');
    }
    const intervals: { [name: string]: number } = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    const singular: { [name: string]: string } = {
      year: 'Jahr',
      month: 'Monat',
      week: 'Woche',
      day: 'Tag',
      hour: 'Stunde',
      minute: 'Minute',
      second: 'Sekunde',
    };

    const plural: { [name: string]: string } = {
      year: 'Jahren',
      month: 'Monaten',
      week: 'Wochen',
      day: 'Tagen',
      hour: 'Stunden',
      minute: 'Minuten',
      second: 'Sekunden',
    };

    let counter;
    // eslint-disable-next-line guard-for-in
    for (const i in intervals) {
      counter = Math.floor(seconds / intervals[i]);
      if (counter > 0) {
        if (counter === 1) {
          return 'vor ' + counter + ' ' + singular[i];
        } else {
          return 'vor ' + counter + ' ' + plural[i];
        }
      }
    }

    return value;
  }
}
