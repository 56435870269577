<div
  *ngIf="!inviteOnly && !isAI"
  class="dot"
  [style.border-color]="colorCode"
  [style.background-color]="colorCode"
  [style.color]="textColor"
  [style.height]="size + 'px'"
  [style.width]="size + 'px'"
  [matTooltip]="tooltip"
>
  <span
    [style.height]="size + 'px'"
    [style.width]="size + 'px'"
    [style.font-size]="size / 2 + 'px'"
    [style.line-height]="size + 'px'"
    [style.font-weight]="'bold'"
    >{{ initials }}</span
  >
</div>
<div
  *ngIf="inviteOnly && !isAI"
  class="dot-invited"
  [style.height]="size + 'px'"
  [style.width]="size + 'px'"
  [matTooltip]="tooltip"
>
  <app-nista-avatar
    avatar="nokay"
    [size]="size"
    bgColorOverride="rgba(255, 255, 255, 0.2)"
  >
  </app-nista-avatar>
</div>
<div
  *ngIf="isAI"
  [style.height]="size + 'px'"
  [style.width]="size + 'px'"
  [matTooltip]="tooltip"
>
  <img
    src="/assets/nista_AI.svg"
    [style.height.px]="size"
    [style.width.px]="size"
  />
</div>
