import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject, filter, takeUntil } from 'rxjs';
import { ErrorhandlerService } from './errorhandler.service';
import { SignalRConnection, SignalRService } from './signal-rservice.service';
import { EnWorkspaceTierDTO } from './workspace-service-client';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceEventsService implements OnDestroy {
  connection?: SignalRConnection;

  private groupUpdatedSubject: Subject<GroupUpdatedEvent> = new Subject();
  get groupUpdated(): Observable<GroupUpdatedEvent> {
    return this.groupUpdatedSubject.asObservable();
  }

  private groupCreatedSubject: Subject<GroupCreatedEvent> = new Subject();
  get groupCreated(): Observable<GroupCreatedEvent> {
    return this.groupCreatedSubject.asObservable();
  }

  private groupDeletedSubject: Subject<string> = new Subject();
  get groupDeleted(): Observable<string> {
    return this.groupDeletedSubject.asObservable();
  }

  private reportUpdatedSubject: Subject<ReportUpdatedEvent> = new Subject();
  get reportUpdated(): Observable<ReportUpdatedEvent> {
    return this.reportUpdatedSubject.asObservable();
  }

  private reportCreatedSubject: Subject<ReportCreatedEvent> = new Subject();
  get reportCreated(): Observable<ReportCreatedEvent> {
    return this.reportCreatedSubject.asObservable();
  }

  private reportDeletedSubject: Subject<string> = new Subject();
  get reportDeleted(): Observable<string> {
    return this.reportDeletedSubject.asObservable();
  }

  private dataPointCreatedSubject: Subject<DataPointCreatedEvent> =
    new Subject();
  get dataPointCreated(): Observable<DataPointCreatedEvent> {
    return this.dataPointCreatedSubject.asObservable();
  }

  private dataPointUpdatedSubject: Subject<string> = new Subject();
  get dataPointUpdated(): Observable<string> {
    return this.dataPointUpdatedSubject.asObservable();
  }

  private dataPointDeletedSubject: Subject<string> = new Subject();
  get dataPointDeleted(): Observable<string> {
    return this.dataPointDeletedSubject.asObservable();
  }

  private dataPointDataUpdatedSubject: Subject<DataPointDataUpdatedEvent> =
    new Subject();
  get dataPointDataUpdated(): Observable<DataPointDataUpdatedEvent> {
    return this.dataPointDataUpdatedSubject.asObservable();
  }

  private dataPointExportCreatedSubject: Subject<DataPointExportCreatedEvent> =
    new Subject();
  get dataPointExportCreated(): Observable<DataPointExportCreatedEvent> {
    return this.dataPointExportCreatedSubject.asObservable();
  }

  private calculationCreatedSubject: Subject<CalculationEvent> = new Subject();
  get calculationCreated(): Observable<CalculationEvent> {
    return this.calculationCreatedSubject.asObservable();
  }

  private calculationDeletedSubject: Subject<CalculationEvent> = new Subject();
  get calculationDeleted(): Observable<CalculationEvent> {
    return this.calculationDeletedSubject.asObservable();
  }

  private facilityCreatedSubject: Subject<string> = new Subject();
  get facilityCreated(): Observable<string> {
    return this.facilityCreatedSubject.asObservable();
  }

  private fileUpdatedSubject: Subject<string> = new Subject();
  get fileUpdated(): Observable<string> {
    return this.fileUpdatedSubject.asObservable();
  }

  private fileDeletedSubject: Subject<string> = new Subject();
  get fileDeleted(): Observable<string> {
    return this.fileDeletedSubject.asObservable();
  }

  private fileGroupUpdatedSubject: Subject<string> = new Subject();
  get fileGroupUpdated(): Observable<string> {
    return this.fileGroupUpdatedSubject.asObservable();
  }

  private fileGroupDeletedSubject: Subject<string> = new Subject();
  get fileGroupDeleted(): Observable<string> {
    return this.fileGroupDeletedSubject.asObservable();
  }

  private initiativeBoardUpdatedSubject: Subject<string> = new Subject();
  get initiativeBoardUpdated(): Observable<string> {
    return this.initiativeBoardUpdatedSubject.asObservable();
  }

  private initiativeUpdatedSubject: Subject<string> = new Subject();
  get initiativeUpdated(): Observable<string> {
    return this.initiativeUpdatedSubject.asObservable();
  }

  private initiativeCreatedSubject: Subject<string> = new Subject();
  get initiativeCreated(): Observable<string> {
    return this.initiativeCreatedSubject.asObservable();
  }

  private initiativeDeletedSubject: Subject<string> = new Subject();
  get initiativeDeleted(): Observable<string> {
    return this.initiativeDeletedSubject.asObservable();
  }

  private taskUpdatedSubject: Subject<string> = new Subject();
  get taskUpdated(): Observable<string> {
    return this.taskUpdatedSubject.asObservable();
  }

  private taskCreatedSubject: Subject<string> = new Subject();
  get taskCreated(): Observable<string> {
    return this.taskCreatedSubject.asObservable();
  }

  private taskDeletedSubject: Subject<string> = new Subject();
  get taskDeleted(): Observable<string> {
    return this.taskDeletedSubject.asObservable();
  }

  private edaLiveDataCreatedSubject: Subject<string> = new Subject();
  get edaLiveDataCreated(): Observable<string> {
    return this.edaLiveDataCreatedSubject.asObservable();
  }

  private edaLiveDataDeletedSubject: Subject<string> = new Subject();
  get edaLiveDataDeleted(): Observable<string> {
    return this.edaLiveDataDeletedSubject.asObservable();
  }

  private solarEdgeLiveDataCreatedSubject: Subject<string> = new Subject();
  get solarEdgeLiveDataCreated(): Observable<string> {
    return this.solarEdgeLiveDataCreatedSubject.asObservable();
  }

  private solarEdgeLiveDataDeletedSubject: Subject<string> = new Subject();
  get solarEdgeLiveDataDeleted(): Observable<string> {
    return this.solarEdgeLiveDataDeletedSubject.asObservable();
  }

  private huaweiLiveDataCreatedSubject: Subject<string> = new Subject();
  get huaweiLiveDataCreated(): Observable<string> {
    return this.huaweiLiveDataCreatedSubject.asObservable();
  }

  private huaweiLiveDataDeletedSubject: Subject<string> = new Subject();
  get huaweiLiveDataDeleted(): Observable<string> {
    return this.huaweiLiveDataDeletedSubject.asObservable();
  }

  private distribuce24LiveDataCreatedSubject: Subject<string> = new Subject();
  get distribuce24LiveDataCreated(): Observable<string> {
    return this.distribuce24LiveDataCreatedSubject.asObservable();
  }

  private distribuce24LiveDataDeletedSubject: Subject<string> = new Subject();
  get distribuce24LiveDataDeleted(): Observable<string> {
    return this.distribuce24LiveDataDeletedSubject.asObservable();
  }

  private workspaceDeletedSubject: Subject<WorkspaceDeletedEvent> =
    new Subject();
  get workspaceDeleted(): Observable<WorkspaceDeletedEvent> {
    return this.workspaceDeletedSubject.asObservable();
  }

  private workspaceMarkedForDeleteSubject: Subject<WorkspaceMarkedForDeleteEvent> =
    new Subject();
  get workspaceMarkedForDelete(): Observable<WorkspaceMarkedForDeleteEvent> {
    return this.workspaceMarkedForDeleteSubject.asObservable();
  }

  private workspaceTierUpdatedSubject: Subject<WorkspaceTierUpdatedEvent> =
    new Subject();
  get workspaceTierUpdated(): Observable<WorkspaceTierUpdatedEvent> {
    return this.workspaceTierUpdatedSubject.asObservable();
  }

  private workspaceUpdatedSubject: Subject<WorkspaceUpdatedEvent> =
    new Subject();
  get workspaceUpdated(): Observable<WorkspaceUpdatedEvent> {
    return this.workspaceUpdatedSubject.asObservable();
  }

  private analysisCreatedSubject: Subject<AnalysisCreatedEvent> = new Subject();
  get analysisCreated(): Observable<AnalysisCreatedEvent> {
    return this.analysisCreatedSubject.asObservable();
  }

  private analysisDeletedSubject: Subject<AnalysisDeletedEvent> = new Subject();
  get analysisDeleted(): Observable<AnalysisDeletedEvent> {
    return this.analysisDeletedSubject.asObservable();
  }

  private analysisUpdatedSubject: Subject<AnalysisUpdatedEvent> = new Subject();
  get analysisUpdated(): Observable<AnalysisUpdatedEvent> {
    return this.analysisUpdatedSubject.asObservable();
  }

  private analysisStatusUpdatedSubject: Subject<AnalysisStatusUpdatedEvent> =
    new Subject();
  get analysisStatusUpdated(): Observable<AnalysisStatusUpdatedEvent> {
    return this.analysisStatusUpdatedSubject.asObservable();
  }

  private violationCreatedSubject: Subject<ViolationCreatedEvent> =
    new Subject();
  get violationCreated(): Observable<ViolationCreatedEvent> {
    return this.violationCreatedSubject.asObservable();
  }

  private violationDeletedSubject: Subject<ViolationDeletedEvent> =
    new Subject();
  get violationDeleted(): Observable<ViolationDeletedEvent> {
    return this.violationDeletedSubject.asObservable();
  }

  private violationUpdatedSubject: Subject<ViolationUpdatedEvent> =
    new Subject();
  get violationUpdated(): Observable<ViolationUpdatedEvent> {
    return this.violationUpdatedSubject.asObservable();
  }

  private connectedSubject = new ReplaySubject<boolean>(1);

  private destroyed$ = new Subject<void>();

  constructor(
    private signalRService: SignalRService,
    private errorHandler: ErrorhandlerService
  ) {
    this.subscribeUpdateEvents();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.connection?.disconnect().subscribe();
  }

  private subscribeUpdateEvents() {
    this.connection = this.signalRService.startConnection('/hubs/workspace');

    this.connection.collect(
      this.connection.connect().subscribe({
        next: () => {
          if (!this.connection) {
            return;
          }

          this.connection.collect(
            this.connection
              .on('GroupCreated', (...data: any[]) => ({
                groupId: String(data[0][0]),
              }))
              .subscribe(this.groupCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('GroupDeleted', (...data: any[]) => String(data[0]))
              .subscribe(this.groupDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<GroupUpdatedEvent>('GroupUpdated', (...data: any[]) => ({
                groupId: data[0][0],
              }))
              .subscribe(this.groupUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on('ReportCreated', (...data: any[]) => ({
                reportId: String(data[0][0]),
              }))
              .subscribe(this.reportCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('ReportDeleted', (...data: any[]) => String(data[0]))
              .subscribe(this.reportDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<ReportUpdatedEvent>('ReportUpdated', (...data: any[]) => ({
                reportId: data[0][0],
              }))
              .subscribe(this.reportUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on('DataPointCreated', (...data: any[]) => ({
                dataPointId: String(data[0][0]),
                originId: String(data[0][1]),
              }))
              .subscribe(this.dataPointCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('DataPointDeleted', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.dataPointDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('DataPointUpdated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.dataPointUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<DataPointDataUpdatedEvent>(
                'DataPointDataUpdated',
                (...data: any[]) => ({
                  dataPointId: data[0][0],
                  version: Number.parseFloat(data[0][1]),
                  unit: data[0][2],
                })
              )
              .subscribe(this.dataPointDataUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<CalculationEvent>('CalculationCreated', (...data: any[]) => ({
                calculationId: data[0][0],
                isSpark: data[0][1],
              }))
              .subscribe(this.calculationCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<CalculationEvent>('CalculationDeleted', (...data: any[]) => ({
                calculationId: data[0][0],
                isSpark: data[0][1],
              }))
              .subscribe(this.calculationDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('FacilityCreated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.facilityCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('FileUpdated', (...data: any[]) => String(data[0]))
              .subscribe(this.fileUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('FileDeleted', (...data: any[]) => String(data[0]))
              .subscribe(this.fileDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('FileGroupUpdated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.fileGroupUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('FileGroupDeleted', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.fileGroupDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('InitiativeBoardUpdated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.initiativeBoardUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('InitiativeCreated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.initiativeCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('InitiativeUpdated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.initiativeUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('InitiativeDeleted', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.initiativeDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('TaskCreated', (...data: any[]) => String(data[0]))
              .subscribe(this.taskCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('EdaLiveDataCreated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.edaLiveDataCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('EdaLiveDataDeleted', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.edaLiveDataDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('SolarEdgeLiveDataCreated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.solarEdgeLiveDataCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('SolarEdgeLiveDataDeleted', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.solarEdgeLiveDataDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('HuaweiLiveDataCreated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.huaweiLiveDataCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('HuaweiLiveDataDeleted', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.huaweiLiveDataDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('Distribuce24LiveDataCreated', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.distribuce24LiveDataCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('Distribuce24LiveDataDeleted', (...data: any[]) =>
                String(data[0])
              )
              .subscribe(this.distribuce24LiveDataDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('TaskUpdated', (...data: any[]) => String(data[0]))
              .subscribe(this.taskUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<string>('TaskDeleted', (...data: any[]) => String(data[0]))
              .subscribe(this.taskDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<DataPointExportCreatedEvent>(
                'DataPointExportCreated',
                (...data: any[]) => ({
                  dataPointId: data[0][0],
                  exportFileId: data[0][1],
                })
              )
              .subscribe(this.dataPointExportCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<WorkspaceDeletedEvent>(
                'WorkspaceDeleted',
                (...data: any[]) => ({
                  workspaceId: data[0][0],
                })
              )
              .subscribe(this.workspaceDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<WorkspaceMarkedForDeleteEvent>(
                'WorkspaceMarkedForDelete',
                (...data: any[]) => ({
                  workspaceId: data[0][0],
                  workspaceName: data[0][1],
                  deletedBy: data[0][2],
                })
              )
              .subscribe(this.workspaceMarkedForDeleteSubject)
          );

          this.connection.collect(
            this.connection
              .on<WorkspaceTierUpdatedEvent>(
                'WorkspaceTierUpdated',
                (...data: any[]) => ({
                  workspaceId: data[0][0],
                  tier: data[0][1],
                })
              )
              .subscribe(this.workspaceTierUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<WorkspaceUpdatedEvent>(
                'WorkspaceUpdated',
                (...data: any[]) => ({
                  workspaceId: data[0][0],
                  workspaceName: data[0][1],
                })
              )
              .subscribe(this.workspaceUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<AnalysisCreatedEvent>(
                'AnalysisCreated',
                (...data: any[]) => ({
                  analysisId: data[0][0],
                })
              )
              .subscribe(this.analysisCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<AnalysisDeletedEvent>(
                'AnalysisDeleted',
                (...data: any[]) => ({
                  analysisId: data[0][0],
                })
              )
              .subscribe(this.analysisDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<AnalysisUpdatedEvent>(
                'AnalysisUpdated',
                (...data: any[]) => ({
                  analysisId: data[0][0],
                })
              )
              .subscribe(this.analysisUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<AnalysisStatusUpdatedEvent>(
                'AnalysisStatusUpdated',
                (...data: any[]) => ({
                  analysisId: data[0][0],
                })
              )
              .subscribe(this.analysisStatusUpdatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<ViolationCreatedEvent>(
                'ViolationCreated',
                (...data: any[]) => ({
                  violationId: data[0][0],
                })
              )
              .subscribe(this.violationCreatedSubject)
          );

          this.connection.collect(
            this.connection
              .on<ViolationDeletedEvent>(
                'ViolationDeleted',
                (...data: any[]) => ({
                  violationId: data[0][0],
                })
              )
              .subscribe(this.violationDeletedSubject)
          );

          this.connection.collect(
            this.connection
              .on<ViolationUpdatedEvent>(
                'ViolationUpdated',
                (...data: any[]) => ({
                  violationId: data[0][0],
                  stateChanged: data[0][1],
                  newState: data[0][2].toLocaleLowerCase(),
                })
              )
              .subscribe(this.violationUpdatedSubject)
          );

          this.connectedSubject.next(true);
        },
        error: (err) => {
          this.errorHandler.error(err);
        },
      })
    );
  }

  public registerForWorkspace(workspaceId: string | undefined) {
    this.connectedSubject
      .pipe(
        takeUntil(this.destroyed$),
        filter((item) => item)
      )
      .subscribe({
        next: () => {
          this.connection?.collect(
            this.connection
              .send('RegisterForWorkspace', workspaceId)
              .subscribe({ error: (err) => this.errorHandler.error(err) })
          );
        },
      });
  }
}

export interface DataPointCreatedEvent {
  dataPointId: string;
  originId: string;
}

export interface CalculationEvent {
  calculationId: string;
  isSpark: boolean;
}

export interface DataPointExportCreatedEvent {
  dataPointId: string;
  exportFileId: string;
}

export interface DataPointDataUpdatedEvent {
  dataPointId: string;
  version: number;
  unit: string;
}

export interface GroupUpdatedEvent {
  groupId: string;
}

export interface GroupCreatedEvent {
  groupId: string;
}

export interface ReportUpdatedEvent {
  reportId: string;
}

export interface ReportCreatedEvent {
  reportId: string;
}

export interface WorkspaceDeletedEvent {
  workspaceId: string;
}

export interface WorkspaceMarkedForDeleteEvent {
  workspaceId: string;
  workspaceName: string;
  deletedBy: string;
}

export interface WorkspaceTierUpdatedEvent {
  workspaceId: string;
  tier: EnWorkspaceTierDTO;
}

export interface WorkspaceUpdatedEvent {
  workspaceId: string;
  workspaceName: string;
}

export interface AnalysisCreatedEvent {
  analysisId: string;
}

export interface AnalysisDeletedEvent {
  analysisId: string;
}

export interface AnalysisUpdatedEvent {
  analysisId: string;
}

export interface AnalysisStatusUpdatedEvent {
  analysisId: string;
}

export interface ViolationCreatedEvent {
  violationId: string;
}

export interface ViolationDeletedEvent {
  violationId: string;
}

export interface ViolationUpdatedEvent {
  violationId: string;
  stateChanged: boolean;
  newState: string;
}
