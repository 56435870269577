import { Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  Subscriber,
  catchError,
  mergeMap,
  retry,
  takeUntil,
} from 'rxjs';
import {
  DataPointClient,
  DataPointListResponse,
  DataPointResponseBase,
  GetDataQualityRequest,
  GetDataRequest,
  GetDataResponse,
  GetQualityStatisticResponse,
} from './datapoint-service-client';
import { WorkspaceService } from './workspace.service';

@Injectable({
  providedIn: 'root',
})
export class DatapointService {
  private queue = new Subject<Loader | DatapointLoader | DatapointListLoader>();

  constructor(
    private dataPointClient: DataPointClient,
    private workspaceService: WorkspaceService
  ) {
    this.queue
      .pipe(
        mergeMap((l) => l.load().pipe(), 3),
        retry({
          count: 3,
          delay: 200,
        }),
        catchError((error, caught) => caught)
      )
      .subscribe();
  }

  public loadDatapointList(
    dataPointIds: string[],
    cancelToken: Observable<void>
  ): Observable<DataPointListResponse> {
    return new Observable<DataPointListResponse>((subscriber) => {
      const workspaceId = this.workspaceService.workspaceId;
      if (!workspaceId) {
        subscriber.complete();
        return;
      }

      const loader = new DatapointListLoader(
        this.dataPointClient,
        dataPointIds,
        workspaceId,
        subscriber,
        cancelToken
      );
      this.queue.next(loader);
    });
  }

  public loadDatapoint(
    dataPointId: string,
    cancelToken: Observable<void>,
    workspaceId: string | undefined = undefined
  ): Observable<DataPointResponseBase> {
    return new Observable<DataPointResponseBase>((subscriber) => {
      const currentWorkspaceId =
        workspaceId ?? this.workspaceService.workspaceId;
      if (!currentWorkspaceId) {
        subscriber.complete();
        return;
      }

      const loader = new DatapointLoader(
        this.dataPointClient,
        dataPointId,
        currentWorkspaceId,
        subscriber,
        cancelToken
      );
      this.queue.next(loader);
    });
  }

  public loadDatapointQuality(
    dataPointId: string,
    cancelToken: Observable<void>
  ): Observable<GetQualityStatisticResponse> {
    return new Observable<GetQualityStatisticResponse>((subscriber) => {
      const workspaceId = this.workspaceService.workspaceId;
      if (!workspaceId) {
        subscriber.error();
        return;
      }

      const request = new GetDataQualityRequest();
      this.dataPointClient
        .getQualityStatistic(dataPointId, workspaceId, request)
        .subscribe(subscriber);
    });
  }

  public loadData(
    dataPointId: string,
    request: GetDataRequest,
    cancelToken: Observable<void>
  ): Observable<GetDataResponse> {
    return new Observable<GetDataResponse>((subscriber) => {
      const workspaceId = this.workspaceService.workspaceId;
      if (!workspaceId) {
        subscriber.complete();
        return;
      }

      const loader = new Loader(
        this.dataPointClient,
        dataPointId,
        workspaceId,
        request,
        subscriber,
        cancelToken
      );
      this.queue.next(loader);
    });
  }
}

export class Loader {
  constructor(
    private dataPointClient: DataPointClient,
    private dataPointId: string,
    private workspaceId: string,
    private request: GetDataRequest,
    private result: Subscriber<GetDataResponse>,
    private cancelToken: Observable<void>
  ) {}

  public load(): Observable<GetDataResponse> {
    return new Observable((sub) => {
      this.dataPointClient
        .getData(this.dataPointId, this.workspaceId, this.request)
        .pipe(takeUntil(this.cancelToken))
        .subscribe({
          next: (result) => {
            this.result.next(result);
            sub.next(result);
          },
          error: (err) => {
            this.result.error(err);
            sub.error(err);
          },
          complete: () => {
            this.result.complete();
            sub.complete();
          },
        });
    });
  }
}

export class DatapointLoader {
  loaderObservable?: Observable<DataPointResponseBase>;

  constructor(
    private dataPointClient: DataPointClient,
    private dataPointId: string,
    private workspaceId: string,
    private result: Subscriber<DataPointResponseBase>,
    private cancelToken: Observable<void>
  ) {}

  public load(): Observable<DataPointResponseBase> {
    return new Observable((sub) => {
      this.dataPointClient
        .getDataPoint(this.dataPointId, this.workspaceId)
        .pipe(takeUntil(this.cancelToken))
        .subscribe({
          next: (result) => {
            this.result.next(result);
            sub.next(result);
          },
          error: (err) => {
            this.result.error(err);
            sub.error(err);
          },
          complete: () => {
            this.result.complete();
            sub.complete();
          },
        });
    });
  }
}

export class DatapointListLoader {
  loaderObservable?: Observable<DataPointListResponse>;

  constructor(
    private dataPointClient: DataPointClient,
    private dataPointIds: string[],
    private workspaceId: string,
    private result: Subscriber<DataPointListResponse>,
    private cancelToken: Observable<void>
  ) {}

  public load(): Observable<DataPointListResponse> {
    return new Observable((sub) => {
      this.dataPointClient
        .getDataPoints(
          this.workspaceId,
          null,
          undefined,
          undefined,
          null,
          undefined,
          this.dataPointIds
        )
        .pipe(takeUntil(this.cancelToken))
        .subscribe({
          next: (result) => {
            this.result.next(result);
            sub.next(result);
          },
          error: (err) => {
            this.result.error(err);
            sub.error(err);
          },
          complete: () => {
            this.result.complete();
            sub.complete();
          },
        });
    });
  }
}
