<div
  class="input-wrapper"
  [class.input-small]="size === 'small'"
  [class.input-medium]="size === 'medium'"
  [class.input-large]="size === 'large'"
>
  <div class="header" *ngIf="title">
    <div class="label title">{{ title }}</div>
    <div class="label optional" *ngIf="showOptional === true">Optional</div>
    <div class="label optional" *ngIf="showOptional === false">Pflichtfeld</div>
  </div>
  <div
    class="editbox-border"
    [class.error]="isError"
    [class.valid]="isValid"
    [class.disabled]="disabled"
  >
    <ng-container *ngTemplateOutlet="inputElement"></ng-container>
  </div>
  <span class="label">
    <ng-content select="[help-content]"></ng-content>
    <ng-container *ngIf="helpText">{{ helpText }}</ng-container>
  </span>
</div>

<ng-template #singleLine>
  <app-gnista-icon
    [icon]="iconLeft"
    [iconSize]="size"
    *ngIf="iconLeft"
  ></app-gnista-icon>
  <input
    #singleLineInput
    [appEditableFocusable]="autoFocus"
    mousedown-stop-propagation
    (focus)="onFocus($event)"
    (input)="onInput($event)"
    (blur)="onBlur($event)"
    class="editbox"
    [type]="isPassword ? 'password' : 'text'"
    [attr.disabled]="disabled ? true : null"
    [attr.readOnly]="!editAllowed ? true : null"
    [placeholder]="placeholder ?? ''"
    [value]="displayValue ?? ''"
    [matAutocomplete]="auto"
  />
  <app-gnista-icon
    [icon]="iconRight"
    [iconSize]="size"
    *ngIf="iconRight"
  ></app-gnista-icon>
</ng-template>
<ng-template #multiLine>
  <textarea
    #multiLineInput
    [rows]="numberOfLines"
    mousedown-stop-propagation
    [appEditableFocusable]="autoFocus"
    (focus)="onFocus($event)"
    (input)="onInput($event)"
    (blur)="onBlur($event)"
    [placeholder]="placeholder ?? ''"
    class="editbox"
    [attr.disabled]="disabled ? true : null"
    [attr.readOnly]="!editAllowed ? true : null"
    [value]="displayValue ?? ''"
  ></textarea>
</ng-template>

<mat-autocomplete #auto (optionSelected)="optionSelected($event)">
  <mat-option
    *ngFor="let option of autoCompleteList; trackBy: trackKey"
    [value]="option.key"
  >
    <ng-container
      *ngTemplateOutlet="autoCompleteTemplate; context: { $implicit: option }"
    >
    </ng-container>
  </mat-option>
</mat-autocomplete>
