import { Injectable, OnDestroy } from '@angular/core';
import { map, Observable, Subject, switchMap, take, takeUntil } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { IdentityEventsService } from './identity-events.service';
import {
  NotificationClient,
  NotificationResponse,
  UpdateNotificationRequest,
  UpdateUserRequest,
  UserNotificationPreferences,
  UserResponse,
} from './notification-service-client';
import { OAuthAuthenticationService } from './OAuth/oAuthAuthentication.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  private static notificationInAppChannelLabels: { [name: string]: string } = {
    addedToNewWorkspace: 'In neues Unternehmen eingeladen',
    taskAssigned: 'Zugewiesene Aufgaben',
    violationDigest: 'Zusammenfassung der Potenziale',
    initiativeUpdated: 'Initiativen wurden verändert',
    initiativeComments: 'Neue Kommentare',
  };

  private static notificationEmailChannelLabels: { [name: string]: string } = {
    addedToNewWorkspace: 'In neues Unternehmen eingeladen',
    taskAssigned: 'Zugewiesene Aufgaben',
    violationDigest: 'Zusammenfassung der Potenziale',
    initiativeUpdated: 'Initiativen wurden verändert',
    initiativeComments: 'Neue Kommentare',
    monthlyDigest: 'Monatlicher Bericht',
  };

  unreadNotifications: Observable<boolean>;

  unreadNotificationsListSubject = new ReplaySubject<NotificationResponse[]>(1);

  get unreadNotificationsList(): Observable<NotificationResponse[]> {
    return this.unreadNotificationsListSubject.asObservable();
  }

  destroyed$ = new Subject<void>();

  userNotificationsSubject = new ReplaySubject<UserResponse>();

  public get userNotifications(): Observable<UserResponse> {
    return this.userNotificationsSubject.asObservable();
  }

  constructor(
    public notificationClient: NotificationClient,
    public identityEventsService: IdentityEventsService,
    public authService: OAuthAuthenticationService
  ) {
    this.identityEventsService.notificationUpdated
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.loadNotifications();
        },
      });

    this.userNotificationsSubject
      .pipe(
        takeUntil(this.destroyed$),
        map((item) => item?.notifications),
        map((item) => item?.filter((a) => a.unread) ?? [])
      )
      .subscribe({
        next: (item) => {
          this.unreadNotificationsListSubject.next(item);
        },
      });

    this.unreadNotifications = this.unreadNotificationsListSubject.pipe(
      takeUntil(this.destroyed$),
      map((item) => item.length > 0)
    );
  }

  public static getNotificationInAppChannelLabel(id: string): string {
    return this.notificationInAppChannelLabels[id];
  }

  public static includeNotificationInAppChannelInSettingsDialog(
    id: string
  ): boolean {
    return NotificationService.getNotificationInAppChannelLabel(id) !== undefined;
  }

  public static getNotificationEmailChannelLabel(id: string): string {
    return this.notificationEmailChannelLabels[id];
  }

  public static includeNotificationEmailChannelInSettingsDialog(
    id: string
  ): boolean {
    return NotificationService.getNotificationEmailChannelLabel(id) !== undefined;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public loadNotifications() {
    if (!this.authService.email) {
      return;
    }

    this.notificationClient.get(this.authService.email).subscribe({
      next: (notification) => {
        this.userNotificationsSubject.next(notification);
      },
    });
  }

  public markAllRead() {
    this.unreadNotificationsList
      .pipe(
        take(1),
        switchMap((item) => item)
      )
      .subscribe({ next: (item) => this.markRead(item.notificationId) });
  }

  public markRead(notificationId?: string) {
    if (!notificationId) {
      return;
    }

    if (!this.authService.email) {
      return;
    }

    const request = new UpdateNotificationRequest({
      unread: false,
    });

    this.notificationClient
      .updateNotificationDetails(
        this.authService.email,
        notificationId,
        request
      )
      .subscribe();
  }

  public updatePreferences(
    preferences?: UserNotificationPreferences
  ): Observable<void> | undefined {
    if (!preferences) {
      return;
    }

    if (!this.authService.email) {
      return;
    }

    const request = new UpdateUserRequest({
      preferences,
    });

    return this.notificationClient.updateUser(this.authService.email, request);
  }
}
