import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-gnista-icon',
  templateUrl: './gnista-icon.component.html',
  styleUrls: ['./gnista-icon.component.scss'],
})
export class GnistaIconComponent implements OnInit, OnChanges {
  @Input()
  icon?: Icon;

  @Input()
  matTooltip!: string;

  @Input()
  iconClass: string[] | string | Set<string> | { [klass: string]: any } = {};

  @Input()
  iconSize: IconSize = 'large';

  @Input()
  inverted = false;

  @Input()
  colorOverride?: string;

  cleanImageSrc?: SafeUrl;

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.calcImageSrc();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      this.icon = changes.icon.currentValue;
      this.calcImageSrc();
    }
  }

  get iconSizePx(): number {
    if (this.iconSize === 'xlarge') {
      return 24;
    }
    if (this.iconSize === 'large') {
      return 24;
    }
    if (this.iconSize === 'medium') {
      return 20;
    }
    if (this.iconSize === 'small') {
      return 16;
    }
    return 20;
  }

  get iconSource(): string {
    return './assets/icons/' + this.iconSizePx + '/' + this.icon + '.svg';
  }

  public calcImageSrc(): void {
    if (!this.iconSource) {
      this.cleanImageSrc = undefined;
    }

    const styleString =
      'url(' +
      this.iconSource +
      ')' +
      (this.inverted ? ', linear-gradient(#fff 0 0)' : '');

    this.cleanImageSrc = this._sanitizer.bypassSecurityTrustStyle(styleString);
  }
}

export type IconSize = keyof typeof ICON_SIZE;

export const ICON_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
};

export type Icon = keyof typeof ICONS;

export const ICONS = {
  add: 'add',
  areaOfInterest: 'areaOfInterest',
  area: 'area',
  arrowBack: 'arrowBack',
  arrowDown: 'arrowDown',
  arrowRight: 'arrowRight',
  arrowRightUp: 'arrowRightUp',
  arrowUp: 'arrowUp',
  brackets: 'brackets',
  calculation: 'calculation',
  calendar: 'calendar',
  chevronDown: 'chevronDown',
  chevronLeft: 'chevronLeft',
  chevronRight: 'chevronRight',
  chevronUp: 'chevronUp',
  close: 'close',
  cloudUpload: 'cloudUpload',
  co2: 'CO2',
  collapse: 'collapse',
  comment: 'comment',
  copy: 'copy',
  currency: 'currency',
  dash: 'dash',
  decollapse: 'decollapse',
  delete: 'delete',
  detach: 'detach',
  distance: 'distance',
  divide: 'divide',
  done: 'done',
  download: 'download',
  dragHandle: 'dragHandle',
  drag: 'drag',
  dropDown: 'dropDown',
  dropDownUp: 'dropDownUp',
  edit: 'edit',
  embedd: 'embedd',
  energy: 'energy',
  error: 'error',
  expand: 'expand',
  eyeClosed: 'eyeClosed',
  eye: 'eye',
  feedback: 'feedback',
  file: 'file',
  filterList: 'filterList',
  force: 'force',
  function: 'function',
  help: 'help',
  image: 'image',
  info: 'info',
  insight: 'insight',
  integral: 'integral',
  legal: 'legal',
  library: 'library',
  link: 'link',
  list: 'list',
  livedata: 'livedata',
  loading: 'loading',
  lock: 'lock',
  massFlowRate: 'massFlowRate',
  mass: 'mass',
  menu: 'menu',
  minus: 'minus',
  more: 'more',
  multiply: 'multiply',
  notificationSubstract: 'notificationSubstract',
  notification: 'notification',
  openBlank: 'openBlank',
  pdf: 'pdf',
  pending: 'pending',
  power: 'power',
  pressure: 'pressure',
  ratio: 'ratio',
  redo: 'redo',
  refresh: 'refresh',
  restore: 'restore',
  result: 'result',
  screenshot: 'screenshot',
  search: 'search',
  send: 'send',
  sensorData: 'sensorData',
  settings: 'settings',
  setting: 'setting',
  shareLink: 'shareLink',
  share: 'share',
  spark: 'spark',
  staticData: 'staticData',
  temperature: 'temperature',
  tile: 'tile',
  timeSeries: 'timeSeries',
  time: 'time',
  undo: 'undo',
  variable: 'variable',
  video: 'video',
  volumeFlowRate: 'volumeFlowRate',
  volume: 'volume',
  warning: 'warning',
  wifiOff: 'wifiOff',
  wifi: 'wifi',
};
